
@use 'base' as *;

body,html{
    background-color: #f0f2f5;
}

*, h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family:"Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
}

::selection {
    background: $primary;
    color: #ffffff;
}
.rounded{
    border-radius: $border-radius !important;
}

section{
    width: 100%;
}

.page-wrapper .page-content .layout-specing{
    flex-direction: column;
}
