@use 'base' as *;

//filter
.filter-wrap{
  .ant-checkbox-group, .ant-radio-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .ant-drawer-header{
    font-weight: bold;
    background-color: var(--ant-primary-7);
    height: 3rem;
    button{
      padding-left: 0;
      padding-right: 0;
    }
    .ant-drawer-title, button{
      display: flex;
      color: $white !important;
      svg{
        width: 1.5em;
        height: 1.5em;
      }
    }
  }

}
