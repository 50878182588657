@use "base";



.options-context-menu {
  .dropdown-item {
    padding: 1rem;
    cursor: pointer;
  }
}

.menu-bar {
  height: 1.5rem !important;
}
