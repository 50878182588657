@use 'base' as *;

// layout start
.bg-home{
  height: 100%;
  align-self: center;
  position: relative;

  &.bg-color{
    background-color: $primary-background-color;
  }

  @media (min-width: 1200px)
  {
    .container {
      max-width: 1140px;
    }
  }

  @media (min-width: 992px){
    .container {
      max-width: 960px;
    }
  }
  @media (min-width: 768px){
    .container {
      max-width: 720px;
    }
  }

  @media (min-width: 576px){
    .container {
      max-width: 540px;
    }
  }

  .container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  .form-signin {
    width: 100%;
    max-width: 400px;
    margin: auto;
  }
  .bg-overlay-white{
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

}

.bg-circle-gradiant{
  background: radial-gradient(circle at center, rgba(47, 85, 212, 0.1) 0%, rgba(47, 85, 212, 0.1) 33.333%, rgba(47, 85, 212, 0.2) 33.333%, rgba(47, 85, 212, 0.2) 66.666%, rgba(47, 85, 212, 0.3) 66.666%, rgba(47, 85, 212, 0.3) 99.999%);
  animation: ani-bg 6s ease-in-out infinite alternate;
}

@keyframes ani-bg {
  0% {
    background-size: 98%;
  }
  50% {
    background-size:102%;
  }
  100% {
    background-size:98%;
  }
}

#root{
  height: 100%;
  input, button, .ant-input-affix-wrapper{
    //border-radius: $border-radius-sm;
  }
}

//layout
.root-layout{
  height: 100%;

  .slider-layout:not(.ant-layout-sider-collapsed){
    min-width: 290px !important;
  }

  .user-avatar{
    background-color: #1890ff;
  }

  .main-menu-icons{
    .ant-menu-item-icon, .ant-btn-icon-only svg {
      font-size: 1.4rem !important;
    }
  }

  .ant-tag{
    display: flex;
    align-items:center;
    border-radius: $border-radius-sm;
    background-color: #1890ff;
    color: #ffffff;
    svg{
      fill: #ffffff;
    }
  }

  .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .trigger:hover {
    color: #1890ff;
  }

  .logo {
    height: 50px;
    margin: 16px;
    display: flex;
    align-items: center;
    img{
      height: 50px;
    }
  }

  .ant-layout-sider-collapsed{
    .logo{
      width: 50px;
      overflow: hidden;
    }
  }

  .site-layout {
    overflow-y: auto;
    margin-top: 10px;
    .affix-header{
      background: rgba(255, 255, 255, 0.68);
      backdrop-filter: blur(10px);
      margin-right: 10px;
      border-radius: $border-radius;
      position: sticky;
      top: 0;
      z-index: 3;
    }

    .site-layout-background {
      background: #ffffff;
      &.content-wrap{
        margin: 20px 10px 20px 0;
        padding: 24px;
        min-height: 280px;
        border-radius: $border-radius;
        overflow: hidden;
      }
    }
  }

  .notification-search{
    width: 500px;
    input{
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    button{
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
    .ant-input-group-addon{
      padding: 0;
      border: none;
    }
  }

  aside{
    &.slider-layout{
      margin: 0.5rem;
      border-radius: $border-radius;
    }
    .main-side-menu{
      margin: 0.5rem;
      width: auto;

      .ant-menu-item{
        border-radius: $border-radius-sm;
      }

      &.ant-menu-inline-collapsed {

        .ant-menu-item, .ant-menu-submenu{
          padding: 0 calc(50% - 25px / 2) !important;
        }

        .ant-menu-submenu-title{
          padding-left: 0;
        }

      }
    }

  }

}


.main-content-wrapper {
  border-radius: $border-radius;
}


section{
  width: 100%;
  display: flex;
}

.app-title {
  span {
    font-family: Colus, Arial, serif;
    font-weight: bold;
  }
}

.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1301;
  width: 100%;
}

@media (max-width: 767px) {
  .bg-home {
    padding: 150px 0;
  }
}

.spinner-provider{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

//footer heart style
.heart{
  fill: $red;
  font-size: large;
}

.lists-wrapper{
  .ant-collapse-header{
    padding: 0 !important;
  }
  .ant-collapse-content-box{
    padding: 0.5rem !important;
  }
}

.list-wrapper{
  min-width: 25rem;

  .list-item{
    cursor: pointer;
    &.card-border{
      border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
      border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    &.selected{
      background-color: var(--ant-primary-1);
      border-color: var(--ant-primary-color);
      border-bottom: var(--bs-border-width) var(--bs-border-style) var(--ant-primary-color) !important;
      transition: background-color, bottom-color, 0.5s;
    }
    &.color-bar{
      border-left: 10px solid #CA5010;
    }
  }
}

.label-value{
  row-gap: 0.5rem;
}
.text-danger{
  color: var(--ant-error-color);
}
.bg-danger{
  background-color: var(--ant-error-color);
}

.un-schedule{
  ul {
    list-style: disc;
    li::marker {
      color: var(--ant-error-color);
    }
  }
}

.ant-drawer-header {
  font-weight: bold;
  background-color: var(--ant-primary-7);
  height: 3rem;
  .ant-drawer-title, button{
    display: flex;
    color: $white !important;
    svg{
      width: 1.2em;
      height: 1.2em;
    }
  }
}
