@use "base";


.table-ctrl{
  .ant-skeleton.ant-skeleton-element.ant-skeleton-active{
    width: 100%
  }

  .html-details{
    max-height: 300px;
    overflow: auto;
  }
}
.custom-pagination-label {
  .ant-pagination-total-text{
    display: table-cell;
    height: 32px !important;
    /* margin-right: 63rem !important; */
    line-height: 30px;
    vertical-align: middle;
    position: absolute;
    left: 24px;
  }
}
