@use 'base' as *;


//ant-drawer styles
.ant-drawer-content-wrapper{
  margin: 10px 10px 10px 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: auto !important;
  transform: translateX(calc(100% + 10px)) !important;
}

.ant-drawer-open{
  .ant-drawer-content-wrapper{
    transform: translateX(0)!important;
  }
}

.ant-drawer-content{
  Border-radius: $border-radius;
}
