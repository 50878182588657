@use "base";

//form-signin
.form-wrap{
  z-index: 1;
  .form-signin{
    // min-height: 25rem;
  }
}

.ant-btn>svg+span,
.ant-btn>.anticon+span,
.ant-btn>span+.anticon {
  margin-left: 0.5em;
}

button{
  svg{
    vertical-align: initial;
  }
}

input[type='text'],
input[type='password'] {
  border-radius: var(--bs-border-radius);
}

.success-color{
  color:var(--ant-success-color);
  svg{
    color: var(--ant-success-color);
  }
}
